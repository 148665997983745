














































































































































































import { RequestPaging } from '@/utils/util'
import { Component, Vue } from 'vue-property-decorator'
import LsDialog from '@/components/ls-dialog.vue'
import LsPagination from '@/components/ls-pagination.vue'
import {
    apiDeliverymanDel,
    apiDeliverymanStatus, // 修改配送员状态
    apiDeliverymanList, // 配送员列表
    changeDeliverymanPassword // 修改配送员密码
} from '@/api/delivery'
@Component({
    components: {
        LsDialog,
        LsPagination
    }
})
export default class Category extends Vue {
    $refs:any
    pager = new RequestPaging()
    form:any = {
        password: '',
        comfirm_password:'',
        deliveryman_id: ""
    }
    rules = {
        password: [
            { validator: this.validatePass, trigger: 'blur' },
            {
                required: true,
                message: '请输入密码',
                trigger: ['blur', 'change']
            }
        ],
        comfirm_password: [
            { validator: this.validatePass2, trigger: 'blur' },
            {
                required: true,
                message: '请输入再次输入密码',
                trigger: ['blur', 'change']
            }
        ]
    }

    formLabelWidth = '80px'
    dialog = false
    // 密码验证
    validatePass2(rule: any, value: any, callback: any) {
        if (value === '') {
            callback(new Error('请再次输入密码'))
        } else if (value != this.form.password) {
            callback(new Error('两次输入密码不一致!'))
        } else {
            callback()
        }
    }
    validatePass(rule: any, value: any, callback: any) {
        if (value === '') {
            callback(new Error('请输入密码'))
        } else {
            if (this.form.comfirm_password !== '') {
                this.$refs.form.validateField('comfirm_password')
            }
            callback()
        }
    }

    apiDeliverymanList = apiDeliverymanList
    getList(page?: number): void {
        console.log(this.pager)
        page && (this.pager.page = page)
        this.pager.request({
            callback: apiDeliverymanList,
            params: {}
        })
    }
    // 是否启用
    handleStatus(value: number, row: object) {
        let params: any = Object.create(null)
        params = row
        params.disable = value;
        params.deliveryman_id = params.id
        apiDeliverymanStatus(params).then(() => {
            this.getList()
        })
    }
    // 删除
    handleDelete(id: number) {
        apiDeliverymanDel({ deliveryman_id:id }).then(() => {
            this.getList()
        })
    }

    created() {
        this.getList()
    }
    // 点击弹出修改密码弹窗
    changePassword(id: Number) {
        this.form = {
            deliveryman_id: id,
            password: '',
            comfirm_password:'',
        }
        this.dialog = true
    }
    handleClose() {
        this.dialog = false
    }
    changePassHandle() {
        this.$refs.form.validate((valid: boolean) => {
            if (valid) {
                changeDeliverymanPassword({...this.form}).then((res) => {
                    this.dialog = false;
                })
            } else {
                return false
            }
        })
    }
}
