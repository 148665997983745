import { render, staticRenderFns } from "./deliveryma.vue?vue&type=template&id=50ee6ecc&scoped=true&"
import script from "./deliveryma.vue?vue&type=script&lang=ts&"
export * from "./deliveryma.vue?vue&type=script&lang=ts&"
import style0 from "./deliveryma.vue?vue&type=style&index=0&id=50ee6ecc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50ee6ecc",
  null
  
)

export default component.exports